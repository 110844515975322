import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import Header from '../section/Header';
import SectionSecond from '../section/SectionSecond';
import SectionFirst from "../section/SectionFirst";
import SectionThird from "../section/SectionThird";
import './Fullpage.css';

// NOTE: if using fullpage extensions/plugins put them here and pass it as props
const pluginWrapper = () => {
    /**
     * require('fullpage.js/vendors/scrolloverflow'); // Optional. When using scrollOverflow:true
     */
};

const anchors = ["header", "content", "footer"];

const Fullpage = props => {
    return (
        <div>
            <Header/>
            <ReactFullpage
                // debug
                anchors={anchors}
                navigation
                pluginWrapper={pluginWrapper}
                navigationTooltips={anchors}
                sectionsColor={["#282c34", "#ff5f45", "#0798ec"]}
                onLeave={(origin, destination, direction) => {
                    console.log("onLeave event", {origin, destination, direction});
                }}
                render={({state, fullpageApi}) => {
                    console.log("render prop change", state, fullpageApi); // eslint-disable-line no-console

                    return (
                        <div>
                            <SectionFirst fullpageApi={fullpageApi} content={"Vitaj na našej stránke"}/>
                            <SectionSecond fullpageApi={fullpageApi}/>
                            <SectionThird fullpageApi={fullpageApi}/>
                        </div>
                    );
                }}
            />
        </div>
    );
};

export default Fullpage;
