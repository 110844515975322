import React from 'react';
import {Button, Icon} from 'antd';

const SectionSecond = props => {
    return (
        <div className='section' id='section2'>
            <h3>Rečník má vyčerpať tému,<br/>
                nie poslucháča</h3>
            <h5>(W. Churchil)</h5>
            <div className='info'>
                <Button.Group size='large'>
                    <Button onClick={() => props.fullpageApi.moveSectionUp()}>
                        <Icon type="up"/>Vyššie
                    </Button>
                    <Button onClick={() => props.fullpageApi.moveSectionDown()}>
                        Nižšie<Icon type="down"/>
                    </Button>
                </Button.Group>
            </div>
        </div>
    );
};

export default SectionSecond;
