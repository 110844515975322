import React from 'react';
import {Button} from 'antd';

const SectionFirst = props => {
    return (
        <div className='section' id='section1'>
            <h3>{props.content}</h3>
            <div className='info'>
                <Button ghost
                        shape="round"
                        icon="down"
                        onClick={() => props.fullpageApi.moveSectionDown()}
                        size='large'>Pokračuj</Button>
            </div>
        </div>
    );
};

export default SectionFirst;
